<template>
    <div class="modebase" style="background:#f8f8f8;padding-top: 50px;padding-bottom: 50px;">
        <el-row class="jieguo" :gutter="20" style="width: 1380px;margin: 0px auto;color: #ccc;font-size: 14px;">
        	<el-col style="height: 400px;line-height: 400px;text-align: center;color: #000000;padding: 15px;" v-if="goodsData.length==0">
        		<el-col style="background: #ffffff;">
        			暂无数据
        		</el-col>
        	</el-col>
        	<el-col v-for="(goodsData1,index) in goodsData" class='hoverbox'>
        		<el-col style="" class='box'>
        			<el-col :span="24" style=' background: #e6e6e6;overflow: hidden;position: relative; ' class="box_img">
        				<div style="position:absolute;">
        					<p v-show='goodsData1.type_id==0' style="width: 20px;"><img src="../assets/img/UserIcon.png" alt="" style="width: 100%;"></p>
        					<p v-show='goodsData1.is_ci==1' style="width: 20px;"><img src="../assets/img/qiyeIcon.png" alt="" style="width: 100%;"></p>
        					<p v-show='goodsData1.type_id==1 && goodsData1.is_ci!=1' style="width: 20px;"><img src="../assets/img/guanfnagIcom.png" alt="" style="width: 100%;"></p>
        				</div>
        				<div class="imgbox">
        					<img :src="common.retun_img(goodsData1.thumbnail)" style="width: 80%;margin: 0 auto; display: block; "
        					 oncontextmenu="return false;" @click="toDetail(goodsData1.id)">
        				</div>
        				<el-tooltip class="item" effect="dark" :content="goodsData1.webfabulous==1? '取消点赞' : '点赞'" placement="top">
        					<div style="position: absolute; right: 6px; top: 6px; cursor: pointer;" @click="fabulous(goodsData1)">
        						<p><img :src="goodsData1.webfabulous==1?imgurlhover:imgurl" alt="" style="width: 20px;"></p>
        						<p style="font-size: 12px; color: #b6b6b6; text-align: center;">{{goodsData1.fabulous}}</p>
        					</div>
        				</el-tooltip>
        				<div style="" class="wendang" v-show="goodsData1.relation_resourceid!=null && goodsData1.relation_resourceid!=0 && goodsData1.resource_type==2" @click="toDetail(goodsData1.relation_resourceid)">
        					<p style="width: 30px;"></p>
        				</div>
        				<div style="" class="donghua" v-show="goodsData1.relation_resourceid!=null && goodsData1.relation_resourceid!=0 && goodsData1.resource_type==3" @click="toDetail(goodsData1.relation_resourceid)">
        					<p style="width: 30px;"></p>
        				</div>
        			</el-col>
        			<el-col class="listbuttom_data">
        				<div :title="goodsData1.name" class="list_top_data">
        					<span style="font-size: 16px; color: #000000;" class="text_over">{{goodsData1.name}}</span> <span>{{goodsData1.author}}</span>
        				</div>
        				<div style="height: 41px;line-height: 41px;color: #787777;" class='xiazai'>
        					<span>{{goodsData1.goldcoin}}币</span>
        					
        					<div style="display: flex;">
        						<span style="display: flex;margin-right: 10px;" ><i class="icon_view"></i>{{goodsData1.look_number}}</span><span style="display: flex;">
        						<i class="icon_dowm"></i>{{goodsData1.download_number}}</span>
        					</div>
        					<div class="tran_buttom">
        						<span @click='toDetail(goodsData1.id)' style="cursor: pointer;" class='shoucang'>
        							<p><img src="../assets/img/xiazai.svg" alt="" width="24px" class="bottom_icon"></p> <span class="texthover">下载并使用</span>
        						</span>
        						<span @click="Shopping(goodsData1.id)" style="cursor: pointer; border-left: 1px solid rgba(255,255,255,0.5); border-right: 1px solid rgba(255,255,255,0.5);"
        						 class='shoucang'>
        							<p><img src="../assets/img/gowuche.svg" alt="" width="24px;" class="bottom_icon"></p> <span class="texthover">购物车</span>
        						</span>
        						<span @click='click_Collection(goodsData1)' style="cursor: pointer;" class='shoucang'>
        							<p><img src="../assets/img/shocang.svg" alt="" width="24px" class="bottom_icon"></p> <span class="texthover">收藏</span>
        						</span>
        
        					</div>
        				</div>
        			</el-col>
        		</el-col>
        	</el-col>
        </el-row>
        <!--分页 -->
        <div class="paginationBox" style="margin-top:20px;text-align: center;">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :current-page="currentPage"
                    :hide-on-single-page="fenyeis"
                    :page-size="per_page"
                    @current-change="handleCurrentChange"
            ></el-pagination>
            <!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
        </div>


		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="30%">
		    <el-select v-model="value" placeholder="请选择收藏的文件夹">
		      <el-option
		        v-for="item in options"
		        :key="item.value"
		        :label="item.label"
		        :value="item.value">
		      </el-option>
		    </el-select>
			<el-input style="width:320px; margin-right: 10px; margin-top: 20px;" placeholder="请输入内容" maxlength=8 v-model="add_ProjectList_input"></el-input>
			<el-button @click='addfolder'>添加文件夹</el-button>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="selection_box()">确 定</el-button>
		  </span>
		</el-dialog>
		<login  v-show="showlogin" @closeDialog="closeDialog"></login>


    </div>
</template>
<script>
	import {add_fabulous,
		del_fabulous,requestSearch_resource,user_fabulous,requestAddpush, requestCatalog,requestSort,requestdirectory_list ,requestaccount_collect_add,requestdirectory_add,requestalipay,requestestalipay,requestcheck_orderstatus} from "../api/request";

	import login from "@/views/Login.vue";
    export default {
		components: {
		    login,
		},
        data () {
            return {
                radio: '1',
                ischoose:true,
                price:10,
                goodsData: [],
                currentPage: 1,
                total:100,
                per_page:0,
				//收藏弹出框
				dialogVisible:false,
                fenyeis:true,
				//select选择框
				options: [],
				//选择框绑定
				value: '',
				//资源数据
				resource_data:'',
				showlogin:'',
                type:"",
                name:"",
				add_ProjectList_input:'',
				imgurl: require('../assets/img/dianzan.svg'),
				imgurlhover: require('../assets/img/dianzanhover.svg'),
            };
        },
        watch: {
            // to , from 分别表示从哪跳转到哪，都是一个对象
            // to.path  ( 表示的是要跳转到的路由的地址 eg: /home );
            $route( to , from ){
                this.type=Number(this.$route.query.type);
                this.name=this.$route.query.name;
                this.list(this.$route.query.type,this.$route.query.name)
            },
        },
        methods:{
			closeDialog(data) {
			    this.showlogin = data;
			},
			//点击收藏函数
			click_Collection(_this){
				if(this.Account.GetSession()==null){
					this.showlogin=true;
					return
				}
				this.dialogVisible = true;
				this.resource_data=_this;
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.options=[]
						//二级列表赋值
						for(let i=0; i<res.ret.length; i++){
							this.options.push({value:res.ret[i].node_id,label:res.ret[i].name})
						}
					});
				});

			},
			
            //列表（分页）赋值
            list(type,name){
                let node_obj={
                    type_id:this.type,
                    name:this.name,
                }
                requestSearch_resource(node_obj).then(res=>{
                    this.total=res.ret.total;
                    this.per_page=res.ret.per_page;
                    
					let token_nav = this.Account.GetToken();
					if (token_nav == null) {
						this.goodsData = res.ret.data;
						// this.$parent.logo_open();
						return
					} else {
						let datas = {}
						user_fabulous(datas).then(e => {
							for (let h = 0; h < e.ret.length; h++) {
								for (let i = 0; i < res.ret.data.length; i++) {
									if (e.ret[h] == res.ret.data[i].id) {
										res.ret.data[i].fabulous = 1;
									}
								}
							}
							this.goodsData = res.ret.data;
						})
					}
					this.goodsData=res.ret.data;
                });
            },
            //收藏弹窗选择文件
            selection_box(){
				if(this.value==''){
					this.$message({
					  type: 'info',
					  message: `请选择文件夹`
					});
					return
				}
				let data={
					"resource_id":this.resource_data.id,
					"node_id":this.value
				}
				requestaccount_collect_add(data).then(res=>{
					this.dialogVisible = false;

				})

			},
            handleCurrentChange(val) {
				//分页数据处理
                let node_obj={
                    type_id:this.type,
                    name:this.name,
                }
                requestSearch_resource(node_obj,val).then(res=>{
                    this.total=res.ret.total;
                    this.per_page=res.ret.per_page;
                    this.goodsData=res.ret.data;
                });
            },
            //跳转查看
            toDetail(id){
                if(this.Account.GetSession()==null){
                    this.$router.push('SignIn')
                    return
                }
                this.$router.push({ path: "/ModeResources", query: { id } });
            },
			//加购
			Shopping(e){
			    let _this=this;
			    requestAddpush(e).then(function (res) {
			        if(res.code==0){
			            _this.$message({
			                message: '加入购物车成功',
			                type: 'success'
			            });
			        }else if(res.code==5012){
			            _this.$confirm('BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？', '提示', {
			                confirmButtonText: '确定',
			                cancelButtonText: '取消',
			                type: 'warning'
			            }).then(() => {
			                _this.$router.push("/VipPrivilge")
			            }).catch(() => {
			                _this.$message({
			                    type: 'info',
			                    message: '已取消前往购买'
			                });
			            });
			        }
			    })
			},
			//添加文件夹
			addfolder() {
				if (this.add_ProjectList_input == '') {
					this.$message({
						message: '请填写项目名称',
						type: 'warning'
					});
					return
				};
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.Child_list.length; h++) {

					if (this.Child_list[h].sequence > max_sequence) {
						max_sequence = this.Child_list[h].sequence;
					}
					if (this.Child_list[h].name == this.add_ProjectList_input) {

						this.$message({
							message: '不可添加重复文件夹名称',
							type: 'warning'
						});
						return
					}
				};
				let sequence = max_sequence + 1;
				let node_idstr = this.common.retun_getnodeid(
					this.current_select_level1_item.node_id,
					2,
					sequence
				);
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: this.current_select_level1_item.id,
					level: 2,
					sequence: sequence,
					node_id: node_idstr,
					parent_node_id: this.current_select_level1_item.node_id
				};
				//添加请求
				let _this = this;
				requestdirectory_add(addData).then((res) => {
					if (res.code == 0) {
			            _this.$message({
							message: '添加成功',
							type: 'success'
						});
			            _this.add_ProjectList_input = ''
					}
					this.dialogVisible_add = false;
					let data = {
						parent_id: 0
					};
					requestdirectory_list(data).then(res => {
						// 一级列表赋值
						let datas = {
							parent_id: res.ret[1].id
						};
						requestdirectory_list(datas).then(res => {
			                _this.Child_list = res.ret;
			                _this.options = [];
							//二级列表赋值
							for (let i = 0; i < res.ret.length; i++) {
			                    _this.options.push({
									value: res.ret[i].node_id,
									label: res.ret[i].name
								})
							}
						});
					});
				});

			},
			//点击收藏函数
			click_Collection(_this){
				if(this.Account.GetSession()==null){
					this.showlogin=true;
					return
				}
				this.dialogVisible = true;
				this.resource_data=_this;
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					this.current_select_level1_item = res.ret[1];
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.Child_list = res.ret;
						this.options=[];
						//二级列表赋值
						for(let i=0; i<res.ret.length; i++){
							this.options.push({value:res.ret[i].node_id,label:res.ret[i].name})
						}
					});
				});
			},
			fabulous(item) {
				let token_nav = this.Account.GetToken();
				let _this = this;
				let resdata=this.goodsData;
				let data = {
					resource_id: item.id
				}
				if (token_nav == null) {
					this.$router.push('SignIn')
					return
				}
				if (item.webfabulous == 1) {
					del_fabulous(data).then(res => {
						for(let i=0; i<_this.goodsData.length; i++){
							if(_this.goodsData[i].id==item.id){
								resdata[i].webfabulous = 0;
								resdata[i].fabulous = resdata[i].fabulous-1;
							}
						}
						_this.goodsData=resdata;
						
					})

				} else {
					add_fabulous(data).then(res => {
						for(let i=0; i<_this.goodsData.length; i++){
							if(_this.goodsData[i].id==item.id){
								resdata[i].webfabulous = 1;
								resdata[i].fabulous = resdata[i].fabulous+1;
							}
						}
						_this.goodsData=resdata;
						console.log(_this.goodsData)
					})
				}

			}
        },
        mounted () {
            this.type=Number(this.$route.query.type);
            this.name=this.$route.query.name;
            this.list(this.$route.query.type,this.$route.query.name)
        },
    };
</script>
<style scoped>
    @import "../style/common.css";

   .shaixuan {
   	background: #fff;
   }
   
   .shaixuan:last-child {
   	border-bottom: none;
   }
   
   .active_sort {
   	color: #007bff;
   }
   
   .shaixuan>div {
   	line-height: 50px;
   	border-bottom: 1px solid #ccc;
   }
   
   .choose {
   	color: #b6492c !important;
   }
   
   /* .hoverbox:hover{background-color:#cad8ed;} */
   
   .shoucang {
   	display: none;
   }
   
   .xiazai {
   	justify-content: space-between;
   	display: flex;
   }
   
   .texthover:hover {
   	color: white;
   }
   
   .bdname {
   	display: block;
   	/* 当前元素本身是inline的，因此需要设置成block模式 */
   	white-space: nowrap;
   	/* 因为设置了block，所以需要设置nowrap来确保不换行 */
   	overflow: hidden;
   	/* 超出隐藏结合width使用截取采用效果*/
   	text-overflow: ellipsis;
   	/* 本功能的主要功臣，超出部分的剪裁方式 */
   	-o-text-overflow: ellipsis;
   	/* 特定浏览器前缀 */
   	text-decoration: none;
   	/* 无用 */
   	float: left;
   	width: 60%;
   }
   
   .hoverbox:nth-child(5n) {
   	margin-right: 0px;
   }
   
   .active_sort {
   	color: #007bff;
   }
   
   .hoverbox {
   	width: 265px;
   	height: 266px;
   	border-radius: 10px;
   	overflow: hidden;
   	margin-right: 12px;
   	margin-bottom: 50px;
	box-shadow: 0px 0px 6px #c4c4c4;
   	padding: 0 !important;
   }
   
   .hoverbox .box {
   	background: #fff;
   	padding: 0 !important;
   }
   
   .box.imgbox img {
   	transform: scale(0.5);
   }
   
   .hoverbox:hover {
   	box-shadow: 0px 0px 6px 0px rgba(196, 62, 27, 0.75);
   }
   
   .hoverbox:hover .tran_buttom {
   	bottom: 0px;
   }
   
   .box .imgbox {
   	/* transform: translateY(calc(50% - 55px)); */
   	display: table-cell;
   	vertical-align: middle;
   	text-align: center;
   	height: 183px;
   	overflow: hidden;
   }
   
   .xiazai {
   	justify-content: space-between;
   	display: flex;
   	margin-left: 6px;
   	margin-right: 6px;
   }
   
   .box:hover .imgbox {
   	overflow: hidden;
   }
   
   .box:hover .imgbox img {
   	transform: scale(1.2);
   	transition: 1s;
   }
   
   .texthover {
   	display: block;
   	color: white;
   	text-align: center;
   }
   
   .list_top_data {
   	height: 41px;
   	line-height: 41px;
   	border-bottom: 1px solid #ebebeb;
   	overflow: hidden;
   	text-overflow: ellipsis;
   	white-space: nowrap;
   	color: #787777;
   	justify-content: space-between;
   	display: flex;
   	margin-right: 6px;
   	margin-left: 6px;
   }
   
   .listbuttom_data {
   	position: relative;
   	background-color: #f8f8f8;
   	border-radius: 0 0 5px 5px;
	
   	overflow: hidden;
   }
   
   .tran_buttom {
   	width: 100%;
   	display: flex;
   	position: absolute;
   	bottom: -83px;
   	left: 0px;
   	transition: 0.5s;
   }
   
   .shoucang {
   	width: 88px;
   	height: 83px;
   	background-color: rgba(196, 62, 28, 0.9);
   	display: block;
   
   }
   
   .shoucang:hover {
   	background-color: rgba(255, 146, 98, 0.9);
   }
   
   .bottom_icon {
   	margin: 15px auto 0 auto;
   	display: block;
   }
   
   .text_over {
   	white-space: nowrap;
   	overflow: hidden;
   	text-overflow: ellipsis;
   }
   
   .box_img {
   	height: 183px !important;
   }
   
   >>>.el-radio__input.is-checked .el-radio__inner {
   	background: #b6492c;
   	border-color: #b6492c;
   }
   
   >>>.el-radio__input.is-checked+.el-radio__label {
   	color: #b6492c;
   }
   >>>.el-link.el-link--default:hover,.el-link.is-underline:hover:after{
   	color: #b6492c;
   	border-bottom-color:#b6492c;
   }
   .donghua{
   	position: absolute; right: 5px; bottom: 5px; cursor: pointer;
   }
   .donghua>p{
   	height: 30px;
   	background: url(../assets/img/donghuaicon.svg) no-repeat center center ;
   	background-size: 100% 100%;
   }
   .donghua:hover>p{
   	background: url(../assets/img/donghuaiconhover.svg) no-repeat center center ;
   	background-size: 100% 100%;
   }
   
   .wendang{
   	position: absolute; right: 0; bottom: 0; cursor: pointer;
   }
   .wendang>p{
   	height: 30px;
   	background: url(../assets/img/wendangicon.svg) no-repeat center center ;
   	background-size: 100% 100%;
   }
   .wendang:hover>p{
   	background: url(../assets/img/wendangiconhover.svg) no-repeat center center ;
   	background-size: 100% 100%;
   }
   .icon_view {
   	margin-top: 10px;
   	margin-right: 5px;
   	width: 20px;
   	height: 20px;
   	display: block;
   	background: url(../assets/img/view.svg) no-repeat center center;
   	background-size: 100% 100%;
   }
   
   .icon_dowm {
   	margin-top: 10px;
   	margin-right: 5px;
   	width: 20px;
   	height: 20px;
   	display: block;
   	background: url(../assets/img/dowm.svg) no-repeat center center;
   	background-size: 100% 100%;
   }
</style>
